import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, setStorageData } from "../../../framework/src/Utilities";
import React from "react";

export interface City {
    id: string;
    type: string;
    attributes: {
        city_ascii: string;
        state_id: string;
        state_name: string;
    };
}

// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    inputValue: string;
    options: City[];
    showCustomUI: boolean;
    hoveredIndex: number | null;
    startDate: Date | null;
    endDate: Date | null;
    selectedCity: City | null;
    inputDestinationValue: string;
    selectDestinationCity: City | null;
    currentLocation: any;
    searchedData: City[];
    originCityError: string;
    destinationCityError: string;
    anchorEl: null | HTMLElement;
    trucTypeInput:string;
    isLoading :boolean,
    // Customizable Area End
}
interface SS { }

export default class LoadBrokerSearchController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getSearchedCityAPICallId: string = "";
    searchLoadAPICallId: string = "";
    searchLoadsByCityAPICallId: string = "";
    getLocationAPICallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage)
        ];

        this.state = {
            inputValue: '',
            options: [],
            showCustomUI: false,
            hoveredIndex: null,
            startDate: null,
            endDate: null,
            selectedCity: null,
            inputDestinationValue: '',
            selectDestinationCity: null,
            currentLocation: null,
            searchedData: [],
            originCityError: '',
            destinationCityError: '',
            anchorEl:null,
            trucTypeInput:"Containers - 12 Tons",
            isLoading:false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        // Customizable Area Start
        // Customizable Area End
    }


    async receive(from: string, message: Message) {
        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let webResponseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (webResponseJson) {
                switch (webApiRequestCallId) {
                    case this.getSearchedCityAPICallId:
                        this.setState({ options: webResponseJson.data });
                        break;
                    case this.searchLoadsByCityAPICallId:
                        this.setState({ searchedData: webResponseJson.data });
                        let searchByCity = {
                            distanceFrom: this.state.selectedCity,
                            distanceTo: this.state.selectDestinationCity,
                            startDate: this.state.startDate,
                            endDate: this.state.endDate,
                            truckType: this.state.trucTypeInput
                        }
                        setStorageData('searchByCity', JSON.stringify(searchByCity));
                        this.handleNavigateLoadBoardList()
                        break;
                    case this.getLocationAPICallId:
                        this.setState({selectedCity: webResponseJson.data[0], isLoading:false})
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start

    // Events
    handleInputChange = (event: React.ChangeEvent<{}>, value: string) => {
        this.setState({ inputValue: value, showCustomUI: value === "" },        
            () => {
                if (this.state.inputValue.length <= 5) {
                    this.getSearchedCity(false)
                }
            }
        );
    };

    handleOnFocus = (event: React.ChangeEvent<{}>) =>{
        this.setState({ showCustomUI: this.state.inputValue === ""})
    }

    handleCityChange = (event: React.ChangeEvent<{}>, newValue: City | null) => {
        if (newValue) {
            this.setState({ selectedCity: newValue });
        } else {
            this.setState({ selectedCity: null });
        }
    };

    handleDestinationInputChange = (event: React.ChangeEvent<{}>, value: string) => {
        this.setState({ inputDestinationValue: value },
            () => {
                if (this.state.inputDestinationValue.length <= 5) {
                    this.getSearchedCity(true)
                }
            }
        );
    };

    handleDestinationCityChange = (event: React.ChangeEvent<{}>, newValue: City | null) => {
        if (newValue) {
            this.setState({ selectDestinationCity: newValue });
        } else {
            this.setState({ selectDestinationCity: null });
        }
    };

    handleMouseEnter = (index: number) => {
        this.setState({ hoveredIndex: index });
    };

    handleMouseLeave = () => {
        this.setState({ hoveredIndex: null });
    };

    handleStartDateChange = (date: Date | null) => {
        this.setState({ startDate: date });
    };

    handleEndDateChange = (date: Date | null) => {
        this.setState({ endDate: date });
    };

    handleNavigateLoadBoardList = async() => {
        const userType = await getStorageData("LoggedInUserType")
        const pathName = userType === "load_broker" ? "LoadBrokerListView" : "TruckerListView";
        const messages = new Message(getName(MessageEnum.NavigationMessage));
        messages.addData(getName(MessageEnum.NavigationTargetMessage), pathName);
        messages.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        this.send(messages)
    }

    // API
    getSearchedCity = async (isDestination: boolean = false) => {
        const header = {
            "Content-Type": configJSON.advancedsearchApiContentType,
            token: await getStorageData("LoginToken")
        };

        const endpoint = isDestination
            ? `${configJSON.searchCityAPIEndPoint}${this.state.inputDestinationValue}`
            : `${configJSON.searchCityAPIEndPoint}${this.state.inputValue}`;

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getSearchedCityAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    searchLoadsByCity = async () => {
        const header = {
            "Content-Type": configJSON.advancedsearchApiContentType,
            token: await getStorageData("LoginToken")
        };
        if (!this.state.selectedCity || !this.state.selectDestinationCity) {
            this.setState({
                originCityError: 'Origin address city is required',
                destinationCityError: 'Destination address city is required'
            })
            return;
        }

        const params = {
            origin_address: {
                city: this.state.selectedCity?.attributes?.city_ascii || "",
                state: this.state.selectedCity?.attributes?.state_name || "",
            },
            destination_address: {
                city: this.state.selectDestinationCity?.attributes?.city_ascii || "",
                state: this.state.selectDestinationCity?.attributes?.state_name || "",
            },
            start_date: this.state.startDate ? this.state.startDate.toISOString().split("T")[0] : "",
            end_date: this.state.endDate ? this.state.endDate.toISOString().split("T")[0] : "",
        };
        const queryParams = new URLSearchParams({
            origin_address: JSON.stringify(params.origin_address),
            destination_address: JSON.stringify(params.destination_address),
            start_date: params.start_date,
            end_date: params.end_date,
        }).toString();

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.searchLoadsByCityAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.searchLoadAPIEndpoint}?${queryParams}`
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleClickTruckType = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorEl:event?.currentTarget});
    }

    handleCloseTruckTypeList = () => {
        this.setState({anchorEl:null});
    };

    handleSelectTrucType = (selectedValue:string) => {
        this.setState({trucTypeInput:selectedValue})
    }

    handleLocationClick = () =>{

        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const { latitude, longitude } = position.coords;
                    this.setState({showCustomUI:false,isLoading:true})
                    this.getUserLocation(Number(latitude.toFixed(4)),Number(longitude.toFixed(4)));
                },
                (error) => {
                    alert('Unable to retrieve your location. Please try again.');
                    console.error('Error getting location:', error.message);
                },
                { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
            );
        } 
    }

    getUserLocation = async(latitude:number,longitude:number) =>{
        const header = {
            "Content-Type": configJSON.advancedsearchApiContentType,
            token: await getStorageData("LoginToken")
        };

        const queryParams = `longitude=${longitude}&latitude=${latitude}`
        
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getLocationAPICallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getLocationAPIEndPoint}?${queryParams}`
        )
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.httpGetMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // Customizable Area End
}