// Customizable Area Start
import React from "react";
import { StyleSheet, TextStyle } from "react-native";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Button } from "@builder/component-library";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import {
  FormControl,
  MenuItem,
  Box
} from "@mui/material";
import Select from '@mui/material/Select';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import TermsAndcondtion from '../../../blocks/termsconditions/src/TermsConditions.web'
const navigation = require("react-navigation");

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController.web";

import UnauthLayout from "../../../components/src/UnauthLayout.web";
import {
  GeneralCenterText,
  PageTitle,
} from "../../../components/src/CustomText.web";
import CustomTextInput from "../../../components/src/CustomTextInput.web";
import CustomStyledCheckbox from "../../../components/src/CustomStyledCheckbox.web.";
import CustomButton from "../../../components/src/CustomButton.web.";
import SocialLoginSection from "../../../components/src/SocialLoginSection.web.";
import CustomToast from "../../../components/src/CustomToast.web";
import PasswordChecklist from "../../../components/src/PasswordChecklist.web";
import { AuthPageTitle } from "../../../components/src/AuthPageTitle.web";

const configJSON = require("./config");

const validationSchema = Yup.object({
  selectUserType: Yup.string()
    .required("User Type is required."),
  firstname: Yup.string()
    .trim()
    .required("First name is required.")
    .min(2, "First name must be at least 2 characters long.")
    .max(50, " First name must be between 2 and 50 characters long.")
    .matches(
      /^[a-z ,.'-]+$/i,
      "First name must not contain numbers or special characters."
    ),
  lastname: Yup.string()
    .trim()
    .required("Last name is required.")
    .min(2, "Last name must be at least 2 characters long.")
    .max(50, " Last name must be between 2 and 50 characters long.")
    .matches(
      /^[a-z ,.'-]+$/i,
      "Last name must not contain numbers or special characters."
    ),
  email: Yup.string()
    .trim()
    .required("Email is required.")
    .email("Please enter a valid email address."),
  phoneNumber: Yup.string()
    .trim()
    .required("Mobile number is required.")
    .matches(/^\+?\d+$/, "Only numbers are allowed in the mobile number")
    .min(12, "Mobile number is too short. Please enter a valid number.")
    .max(15, "Mobile number is too long. Please enter a valid number.")
    .matches(
      configJSON.UsRegex,
      "Invalid country code. Please enter a US country code (+1)"
    ),
  companyName: Yup.string()
    .trim()
    .when('selectUserType', {
      is: 'Load Broker',
      then: Yup.string()
        .required("Company name is required.")
        .min(3, "Company name must be at least 3 characters long.")
        .max(50, "Company name must be between 3 and 50 characters long."),
      otherwise: Yup.string().notRequired(), 
    }),
  password: Yup.string()
    .trim()
    .required("Password is required.")
    .min(8, "Password must be at least 8 characters long.")
    .max(64, "Password cannot exceed 64 characters.")
    .matches(/^\S*$/, "Password must not contain spaces."),
  confirmPassword: Yup.string()
    .trim()
    .required("Password confirmation is required.")
    .oneOf([Yup.ref("password"), null], "Passwords did not match."),
  termAndConAgree: Yup.bool().oneOf(
    [true],
    "You have to agree the Terms and Conditions to Signup."
  ),
});

const newStyles = {
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  termAndConWrapper: {
    marginTop: "-7px",
    marginBottom: "23px",
    display: "inline-flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "2px",
  },
  customLink: {
    color: "#215089",
    fontFamily: "Arial, Helvetica",
    fontSize: "16px",
    fontWeight: "bold",
    textDecoration: "none",
    cursor: "pointer",
  },
  termAndCon: {
    color: "#215089",
    fontFamily: "Arial, Helvetica",
    fontSize: "12px",
    fontWeight: "bold",
    textDecoration: "none",
    cursor: "pointer",
    marginTop: "2px",
  },
  loginWrapper: {
    marginTop: "27px",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  input: {
    height: '56px',
    radius: '8px',
    padding: '10px 8px 10px 8px',
    width: '100%'
  }
};

// Customizable Area End

export class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  render() {
    // Customizable Area Start

    return (
      <>
        <UnauthLayout currentWidth={this.state.width}>
          <Formik
            initialValues={{
              selectUserType: "",
              firstname: "",
              lastname: "",
              email: "",
              phoneNumber: "",
              companyName:"",
              password: "",
              confirmPassword: "",
              termAndConAgree: false,

            }}
            validationSchema={validationSchema}
            onSubmit={this.handleSignUp}
            data-test-id="register-formik"
          >
            {(props) => {
              this.updateRegisterFormProps(props);

              return (
                <Form
                  data-test-id="refister-form"
                  style={newStyles.form as React.CSSProperties}
                  onSubmit={props.handleSubmit}
                >

{!!props.errors.termAndConAgree &&
            props.touched.termAndConAgree && (
              <CustomToast message={props.errors.termAndConAgree || ""} />
            )}
          {!!this.state.errorMessage && (
            <CustomToast message={this.state.errorMessage} />
          )}

                  <PageTitle component="h2" variant="2xl">
                    Sign Up
                  </PageTitle>

                  <Field
                    data-test-id='whoAreYouSelect'
                    component={() => (
                      <Box style={{ width: '100%' }}>
                        {<AuthPageTitle>Who are you? <span style={{ color: 'red' }}>*</span></AuthPageTitle>}
                        <FormControl sx={{ width: '100%' }}>
                          <Select
                            labelId="demo-multiple-name-label"
                            id="demo-multiple-name"
                            data-test-id='selectBtn'
                            value={props.values.selectUserType}
                            style={newStyles.input}
                      
                            onChange={this.handleSelectChange("selectUserType", props)}
                        
                            sx={{
                              height: 50,
                              "& .MuiOutlinedInput-notchedOutline": {
                                borderColor: props.errors.selectUserType
                                ? "red"
                                : "#CBD5E1",
                              },
                              "& .MuiSelect-select": {
                                padding: "2px 2px"
                              },
                            }}
                            displayEmpty={true}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return <span style={{
                                  fontFamily: 'Inter',
                                  color: '#94A3B8',
                                  opacity: 0.9,
                                  fontSize: '13px',
                                  fontWeight:'400'
                                }}>Select user type</span>;
                              }
                              return selected;
                            }}
                            MenuProps={{
                              PaperProps: {
                                sx: {
                                  '& .MuiMenuItem-root': {
                                    height: '42px',
                                    color:'#0F172A',
                                    backgroundColor: '#fff',
                                    padding: '12px 16px',
                                    borderRadius: '0px 0px 8px 8px',
                                    '&:hover': {
                                      backgroundColor: '#FBE1C4',
                                      '&.Mui-selected': {
                                        backgroundColor: '#FBE1C4',
                                      },
                                    },
                                    '&.Mui-selected': {
                                      backgroundColor: '#FBE1C4',
                                      color: '#0F172A',
                                      '&:not(:hover)': {
                                        backgroundColor: '#fff',
                                      },
                                    },
                                  },
                                },
                              },
                            }}
                            IconComponent={KeyboardArrowDownIcon}

                          >
                            {['Trucker',
                              'Load Broker'].map((name) => (
                                <MenuItem
                                  key={name}
                                  value={name}
                                >
                                  {name}
                                </MenuItem>
                              ))}
                          </Select>
                          {props.touched.selectUserType && !props.values.selectUserType && (
                            <div style={{
                              color: '#DC2626',
                              fontSize: 12,
                              fontFamily: 'Inter',
                              marginTop: '5px',
                              fontWeight: '400'
                            }}>
                              {'User type is required'}
                            </div>
                          )}
                        </FormControl>
                      </Box>
                    )
                    }
                  />
                  <Field
                    component={CustomTextInput}
                    label="First Name"
                    data-test-id="txtInputFirstName"
                    placeholder="Your first name"
                    defaultValue={props.values.firstname}
                    onChange={this.handleChange("firstname", props)}
                    error={this.getFieldError("firstname", props)}
                  />
                  <Field
                    component={CustomTextInput}
                    label="Last Name"
                    data-test-id="txtInputLastName"
                    placeholder="Your last name"
                    defaultValue={props.values.lastname}
                    onChange={this.handleChange("lastname", props)}
                    error={this.getFieldError("lastname", props)}
                  />
                  <Field
                    component={CustomTextInput}
                    label="Email"
                    data-test-id="txtInputEmail"
                    placeholder="Your email"
                    defaultValue={props.values.email}
                    onChange={this.handleChange("email", props)}
                    error={this.getFieldError("email", props)}
                  />
                  <Field
                    component={CustomTextInput}
                    label="Mobile Number"
                    data-test-id="txtInputPhoneNumber"
                    placeholder="+1 202 555 1234"
                    defaultValue={props.values.phoneNumber}
                    onChange={this.handleChange("phoneNumber", props)}
                    error={this.getFieldError("phoneNumber", props)}
                  />
                  {props.values.selectUserType === "Load Broker" && 
                  <Field
                    component={CustomTextInput}
                    label="Company Name"
                    data-test-id="txtInputCompanyName"
                    placeholder="Your commpany name"
                    defaultValue={props.values.companyName}
                    onChange={this.handleChange("companyName", props)}
                    error={this.getFieldError("companyName", props)}
                  />
                  }
                  <Field
                    component={CustomTextInput}
                    label="Password"
                    data-test-id="txtInputPassword"
                    secureTextEntry={this.state.enablePasswordField}
                    placeholder="Your password"
                    defaultValue={props.values.password}
                    onChange={this.handleChange("password", props)}
                    error={this.getFieldError("password", props)}
                    rightIcon={
                      <Button
                        data-test-id="btnTogglePassword"
                        aria-label="toggle password visibility"
                        onPress={this.handleTogglePassword}
                        style={{ backgroundColor: "transparent" }}
                        icon={
                          this.state.enablePasswordField ? (
                            <VisibilityOffOutlinedIcon style={{ color: "#94A3B8" }} />
                          ) : (
                            <RemoveRedEyeOutlinedIcon style={{ color: "#94A3B8" }} />
                          )
                        }
                      />
                    }
                  />
                  <Field
                    component={CustomTextInput}
                    label="Confirm Password"
                    data-test-id="txtInputConfirmPassword"
                    secureTextEntry={this.state.enableConfirmPasswordField}
                    placeholder="Your confirm password"
                    defaultValue={props.values.confirmPassword}
                    onChange={this.handleChange("confirmPassword", props)}
                    error={this.getFieldError("confirmPassword", props)}
                    rightIcon={
                      <Button
                        data-test-id="btnToggleConfirmPassword"
                        aria-label="toggle confirm password visibility"
                        onPress={this.handleToggleConfirmPassword}
                        style={{ backgroundColor: "transparent" }}
                        icon={
                          this.state.enableConfirmPasswordField ? (
                            <VisibilityOffOutlinedIcon style={{ color: "#94A3B8" }} />
                          ) : (
                            <RemoveRedEyeOutlinedIcon style={{ color: "#94A3B8" }} />
                          )
                        }
                      />
                    }
                  />
                  {props.touched.password && (
                    <PasswordChecklist password={props.values.password} />
                  )}
                  <Box sx={newStyles.termAndConWrapper}>
                    <CustomStyledCheckbox
                      name="chkAgree"
                      label="By signing up, you agree to our"
                      checked={props.values.termAndConAgree}
                      onChange={(value) =>
                        props.setFieldValue("termAndConAgree", value)
                      }
                      disabled={this.state.isDisable}
                    />
                    <span
                      data-test-id="btnTermAndCondition"
                      onClick={this.goToTermsAndConditions}
                      style={newStyles.termAndCon}
                    >
                      Terms and Conditions
                    </span>
                  </Box>
                  <CustomButton
                    text="SIGN UP"
                    testId="btnEmailRegister"
                    onClick={props.handleSubmit}
                  />
                </Form>
              );
            }}
          </Formik>

          <GoogleOAuthProvider clientId="649592030497-gp3moqh0k2src5r2u5qfaorii1dqkvdc.apps.googleusercontent.com">
            <SocialLoginSection
              onSuccess={this.handleSuccess}
              onError={this.handleError}
            />
          </GoogleOAuthProvider>
          <Box
            data-test-id="btnSignIn"
            onClick={this.goToLogin}
            sx={newStyles.loginWrapper}
          >
            <GeneralCenterText style={styles.smallText as TextStyle}>
              Already have an account?{" "}
              <span style={newStyles.customLink}>Sign In</span>
            </GeneralCenterText>
          </Box>
        </UnauthLayout>
        {this.state.showModal &&
          <TermsAndcondtion navigation={navigation} id={"TermsConditions"} show={this.state.showModal} onAccept={this.handleAccept} onClose={this.handleCloseModal} />
        }

      </>
    );
    // Customizable Area End
  }
}

// Customizable Area Start


const styles = StyleSheet.create({
  smallText: { fontFamily: "Arial, Helvetica", fontSize: 14 },
});

export default EmailAccountRegistration;
// Customizable Area End
