import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from "@mui/material";
import NavigationMenu from "../../navigationmenu/src/NavigationMenu.web";
import HeaderNavigation from "../../navigationmenu/src/HeaderNavigation.web";
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CommuteIcon from '@mui/icons-material/Commute';
import WidgetsIcon from '@mui/icons-material/Widgets';

// Customizable Area End
import DashboardController, {
  Props,
  webConfigJSON
} from "./DashboardController.web";

export default class Dashboard extends DashboardController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  // Customizable Area End

  render() {
    // Customizable Area Start
    const items = [
      {
        icon: <FormatListBulletedIcon sx={{ height: '60px', width: '60px', color: '#0F172A' }} />,
        title: 'Load Board',
        description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore',
        key: 'LoadBrokerSearch',
        path: this.state.LoggedInUserType === 'trucker' ? 'TruckerSearch': 'LoadBrokerSearch'
      },
      {
        icon: <AddCircleOutlineIcon sx={{ height: '60px', width: '60px', color: '#0F172A' }} />,
        title: 'Create Load',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
        key: 'postcreation',
        path: 'PostCreation'
      },
      {
        icon: <WidgetsIcon sx={{ height: '60px', width: '60px', color: '#0F172A' }} />,
        title: 'My Loads',
        description: 'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi',
        key: 'loadboard',
        path: this.state.LoggedInUserType === 'trucker' ? 'MyLoads': 'BrokerLoads'
      }
    ]; 
    const filteredItems = this.state.LoggedInUserType === 'trucker'
    ? items.filter(item => item.title === 'Load Board' || item.title === 'My Loads')
    : items;

    return (
      //Merge Engine DefaultContainer
      <Box sx={webStyles.container}>
        <HeaderNavigation navigation={this.props.navigation} id={""} />
        <Box sx={webStyles.content}>
          <NavigationMenu navigation={this.props.navigation} id={""} />
          <Box sx={webStyles.mainContent}>
            <Box sx={webStyles.cardContainer}>
              <Typography style={webStyles.headerFont} data-testId='Dashboard'>{webConfigJSON.labelTitleText}</Typography>
              <Box sx={webStyles.cardBox}>
                {filteredItems.map((item, itemIndex) => (
                  <Box sx={webStyles.cardStyle} key={itemIndex}>
                    <List>
                      <ListItem
                        alignItems="flex-start"
                        sx={webStyles.listItem}
                        onClick={() => this.handleNavigate(item.path)}
                        data-testId={"navigateDashboard"}
                      >
                        <ListItemIcon>
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText
                          primary={<Typography className="primaryText" sx={webStyles.listPrimaryText}>{item.title}</Typography>}
                          secondary={<Typography sx={webStyles.listSecondaryText}>{item.description}</Typography>}
                        />
                      </ListItem>
                    </List>
                  </Box>
                ))}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  container: {
    flexDirection: "column",
    overflowX: 'hidden',
    display: "flex",
  },  
  mainContent: {
    flex: 1,
    backgroundColor: '#E2E8F0',
    padding: "2rem",
  },
  content: {
    display: "flex",
    flex: 1,
  },
  headerFont: {
    fontSize: '30px',
    fontFamily: "Inter",
    fontWeight: 700,
    color: "#215089"
  },
  cardContainer: {
    borderRadius: '16px',
    border: '2px solid #215089',
    backgroundColor: '#FFFFFF',
    padding: '2rem',
  },  
  cardBox: {
    display: 'flex',
    gap: '30px',
    flexDirection: 'column',
    marginBottom: '5%',
    marginTop: '2%',
  },
  cardStyle: {
    borderRadius: '8px',
    backgroundColor: '#E9EEF3',
    boxShadow: '0px 4px 4px 0px #00000040',
    padding: '20px 22px',
    transition: 'background-color 0.3s',
    '&:hover': {
      backgroundColor: '#FEF5EC',
      '& .MuiSvgIcon-root': {
        color: '#B57730',
      },
      '& .primaryText': {
        color: '#B57730',
      },
    },
  },
  listItem: {
    display: 'flex',
    gap: '5px',
    alignItems: 'center',
  },
  listPrimaryText: {
    fontWeight: 700,
    fontSize: '18px',
    fontFamily: "Inter",
    color: "#0F172A"
  },
  listSecondaryText: {
    color: "#334155",
    fontWeight: 400,
    fontSize: '16px',
    marginTop: '5px',
    fontFamily: "Inter",
  }
};
// Customizable Area End
